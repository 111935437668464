// utils.
import { formatPrice } from "@utils";
import { IncreasePricesTransformed } from "@store/apiSlices/increaseApprovalPrice/utils";
import {
  INTELLIGENCE_MARKET,
  REVENUE_STANDARD,
  TRADING_RANGES,
} from "../utils";

export const IncreasesInfo = ({
  originalMinimumPrice,
  increasePrices,
  increaseType,
  revenueStandard,
}: {
  originalMinimumPrice: number;
  increasePrices: IncreasePricesTransformed;
  increaseType?: string;
  revenueStandard?: string;
}) => {
  let increasePercentage = 0;
  let increasePublicationPrice = 0;
  let increaseMinPublicationPrice = 0;

  const alertPrice = 2000000;

  if (increaseType === INTELLIGENCE_MARKET) {
    increasePercentage = originalMinimumPrice > alertPrice ? 2 : 3;
    increasePublicationPrice = increasePrices.increaseIMPublicationPrice;
    increaseMinPublicationPrice = increasePrices.increaseImMinPublicationPrice;
  }

  if (increaseType === TRADING_RANGES) {
    increasePercentage = originalMinimumPrice > alertPrice ? 4 : 3;
    increasePublicationPrice = increasePrices.increaseRangesPublicationPrice;
  }

  if (
    increaseType === REVENUE_STANDARD &&
    (revenueStandard === "Prime" || revenueStandard === "Sub-Prime")
  ) {
    increasePercentage = 2;
    increasePublicationPrice = increasePrices.increaseRevenuePublicationPrice;
  }

  if (!increasePercentage) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between mt-2">
      <div className="increment-type">{`+${increasePercentage}%: ${increaseType}`}</div>
      <div className="d-flex flex-column align-items-end">
        <div className="d-flex gap-1">
          <small>Publicado:</small>
          <div>{formatPrice(increasePublicationPrice)}</div>
        </div>
        {increaseType === INTELLIGENCE_MARKET && (
          <div className="d-flex gap-1">
            <small>Minimo:</small>
            <div>{formatPrice(increaseMinPublicationPrice)}</div>
          </div>
        )}
      </div>
    </div>
  );
};
